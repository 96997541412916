@use './../../../../../../styles/colors';

.add-or-edit-date-label {
  color: colors.$black;
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  padding-left: 5px;
}

.update-select-controls {
  display: flex;

  .button-arrow {
    height: 100%;

    &:hover {
      filter: drop-shadow(0px 4px 4px #00000090);
    }

    padding: 0 10px;
  }

  button {
    font-size: 30px;

    &.disabled {
      color: colors.$disabled-gray;
      pointer-events: none;
    }

    &:hover {
      color: initial;
    }
  }
}

.current-update-date-label {
  color: colors.$black;
  font-size: 18px;
  font-weight: bold;
  align-self: center;
}