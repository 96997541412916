@use "styles/colors";

.productLine {
  margin-left: 100px;
  display: grid;
  grid-template-columns: 150px 3fr;

  .displayProducts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 25px 0;
  }

  .displayName {
    border-right: solid colors.$white 3px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
  }

  & ~ .productLine .productLineDivider {
    display: block;
  }
}

.productLineDivider {
  display: none;
  grid-column: 2/-1;
  padding: 0 15px;
  border-left: solid colors.$white 3px;
  margin-left: -3px;

  .lineSeparator {
    border-bottom: 2px solid colors.$light-gray;
    position: relative;

    .lineSeparator:before,
    .lineSeparator:after {
      position: absolute;
      bottom: -6px;
      left: 0;
      height: 10px;
      width: 10px;
      background: colors.$light-gray;
      content: '';
      border-radius: 5px;
    }

    .lineSeparator:after {
      right: 0;
      left: auto;
    }
  }
}
