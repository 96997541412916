.confirmation-modal {
  .confirmation-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .confirmation-modal-title {
      text-transform: uppercase;
      letter-spacing: 1.6px;
    }

    .confirmation-modal-text {
      text-align: center;
      margin: 2rem 0;
    }
  }
  .confirmation-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    button {
      min-width: 8.75rem;
    }
  }
}
